<template>
  <form class="filter-rubric card" @submit.prevent="formSubmitHandler">
    <Input
      v-model="filter.title"
      id="filter_title"
      :label="$tc('rubric.filter.title')"
      disable-form-group
    />
    <Input
      v-model="filter.slug"
      id="filter_slug"
      :label="$tc('rubric.filter.slug')"
      disable-form-group
    />
    <Input
      v-model="filter.id"
      id="filter_id"
      :label="$tc('rubric.filter.id')"
      disable-form-group
    />

    <SiteSelect
      v-if="vlm"
      v-model="filter.site"
      :options="sites"
      :label="$tc('rubric.filter.site')"
      id="filter_site"
    />

    <Select
      v-else
      v-model="filter.site"
      :options="sites"
      :label="$tc('rubric.filter.site')"
      id="filter_site"
      disable-form-group
    />
    <div class="filter-rubric__btn-area">
      <ButtonSearch />
      <ButtonResetFilter :on-click="resetFilter" />
    </div>
  </form>
</template>

<script>
import _ from 'lodash'
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'
import RubricFilter from '@/model/RubricFilter'
import ButtonResetFilter from '@/components/buttons/ButtonResetFilter.vue'
import ButtonSearch from '@/components/buttons/ButtonSearch.vue'
import { mapGetters } from 'vuex'
import SiteSelect from '@/components/form/select/SiteSelect.vue'

export default {
  name: 'RubricFilter',
  data () {
    return {
      filter: _.cloneDeep(RubricFilter)
    }
  },
  components: {
    SiteSelect,
    ButtonSearch,
    ButtonResetFilter,
    Input,
    Select
  },
  beforeMount () {
    this.filter = this.$store.getters['rubric/filter']
  },
  computed: {
    ...mapGetters(['vlm']),
    sites () {
      return this.$store.getters['site/allEnabledSorted']()
    }
  },
  methods: {
    formSubmitHandler () {
      this.$store.commit('rubric/setPage', 1)
      this.$store.commit('rubric/setFilter', this.filter)
      this.$store.dispatch('rubric/fetch')
    },
    resetFilter () {
      this.filter = _.cloneDeep(RubricFilter)
      this.$store.commit('rubric/setFilter', this.filter)
      this.$store.dispatch('rubric/fetch')
    }
  }
}
</script>

<style scoped lang="scss">
  .filter-rubric {
    padding: 1rem;
    display: grid;
    gap: 1rem;
    align-items: end;
    @include bp(11) {
      grid-template-columns: 1fr 1fr 1fr 1fr auto;
    }
    &__btn-area {
      display: grid;
      gap: 1rem;
      grid-template-columns: min-content max-content;
      @include bp(11) {
        grid-template-columns: min-content max-content;
      }
    }
  }
</style>
